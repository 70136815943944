import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useGetInspired from 'content-queries/mx/new/get-inspired'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import {
  BasicContent,
  Header,
  MainBanner,
  MainContent,
} from 'mx/components/new'
import { ContentGrid } from 'mx/components/new/content-grid'
import Layout from 'mx/layout'

const GetInspiredPage = ({ path }: { path: string }) => {
  const { data } = useGetInspired()
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Get Inspired',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [
          hero,
          goodDesign,
          tone,
          finishes,
          inspirationGuide,
          ,
          performance,
          quote,
          catalog,
          conversation,
        ],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <Header data={hero} />
      <MainBanner data={goodDesign} />
      <BasicContent data={tone} classname={tw`mt-20 lg:mt-32`} />
      <ContentGrid data={finishes} classname={tw`mt-20 md:mt-48`} />
      <MainContent
        data={inspirationGuide}
        dark
        classname={tw`mt-20 lg:mt-32`}
      />
      <MainContent data={performance} inline classname={tw`mt-20 lg:mt-32`} />
      <MainBanner brown data={quote} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent
        data={catalog}
        inline
        centerCTA
        classname={tw`mt-20 lg:mt-32`}
      />
      <MainContent data={conversation} inline classname={tw`my-20 lg:my-32`} />
    </Layout>
  )
}

export default GetInspiredPage

export const Head = () => {
  const { data } = useGetInspired()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/get-inspired"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
